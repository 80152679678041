import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import { globalTranslations as globalGermanTranslations } from './i18n/locales/de/translations';
import { globalTranslations as globalEnglishTranslations } from './i18n/locales/en/translations';
import { globalTranslations as globalFrenchEnTranslations } from './i18n/locales/fr/translations';
import { globalTranslations as globalItalianTranslations } from './i18n/locales/it/translations';

export enum supportedLanguageCodes {
  ENGLISH = 'en',
  GERMAN = 'de',
  FRENCH = 'fr',
  ITALIAN = 'it'
}

export const fallbackLanguage = supportedLanguageCodes.ENGLISH;

export const PREFERRED_LANGUAGE_LOCAL_STORAGE_KEY = 'PREFERRED_LANGUAGE';

const preferredSelectedLanguage = localStorage.getItem(PREFERRED_LANGUAGE_LOCAL_STORAGE_KEY);

i18n.use(initReactI18next).init({
  debug: true,
  lng: preferredSelectedLanguage || supportedLanguageCodes.ENGLISH,
  fallbackLng: fallbackLanguage,
  interpolation: {
    escapeValue: false // react handles its escapin
  },
  resources: {
    [supportedLanguageCodes.ENGLISH]: {
      ...globalEnglishTranslations
    },
    [supportedLanguageCodes.ITALIAN]: {
      ...globalItalianTranslations
    },
    [supportedLanguageCodes.FRENCH]: {
      ...globalFrenchEnTranslations
    },
    [supportedLanguageCodes.GERMAN]: {
      ...globalGermanTranslations
    }
  }
});

export default i18n;
