import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import { Provider as NiceModalProvider } from '@ebay/nice-modal-react';

import 'typeface-roboto';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import * as Sentry from '@sentry/react';
import { SWRConfig } from 'swr';

import App from 'containers/App';

import * as serviceWorker from './serviceWorker';
import { store } from './store';
import './utils/i18n';

const themes = {
  rush: `${process.env.PUBLIC_URL}/rush-theme.css`,
  zeal: `${process.env.PUBLIC_URL}/zeal-theme.css`,
  affirm: `${process.env.PUBLIC_URL}/affirm-theme.css`,
  light: `${process.env.PUBLIC_URL}/light-theme.css`
};

if (process.env.NODE_ENV !== 'development' && process.env.NODE_ENV !== 'test') {
  Sentry.init({
    dsn: 'https://0d9d3518ae864fca938bf64f646fa78f@sentry.io/1458950'
  });
}

if (process.env.NODE_ENV === 'development') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React);
}

const getDefaultTheme = () => {
  let defaultTheme = 'light';
  const hostname = window.location.hostname;

  if (hostname.includes('zeal') || hostname.includes('bltz')) {
    defaultTheme = 'zeal';
  }
  if (hostname.includes('rush')) {
    defaultTheme = 'rush';
  }

  return defaultTheme;
};

let render = Component =>
  // eslint-disable-next-line react/no-render-return-value
  ReactDOM.render(
    <HelmetProvider>
      <ThemeSwitcherProvider
        themeMap={themes}
        defaultTheme={getDefaultTheme()}
        insertionPoint="styles-insertion-point"
      >
        <Provider store={store}>
          <SWRConfig value={{ provider: () => new Map() }}>
            <Helmet>
              <title>Perx </title>
            </Helmet>
            <Router basename="dashboard">
              <NiceModalProvider>
                <Route path="/" component={Component} />
              </NiceModalProvider>
            </Router>
          </SWRConfig>
        </Provider>
      </ThemeSwitcherProvider>
    </HelmetProvider>,
    document.getElementById('root') || document.createElement('div')
  );
// Render once
render(App);

serviceWorker.register(null);
