import React, { useCallback, useEffect, useState } from 'react';
import { SelectProps, Spin } from 'antd';
import { debounce } from 'lodash';

import { useCampaignList } from 'api/campaigns';
import { Select } from 'components/Layout/Form';

interface CampaignSelectProps extends SelectProps {
  showId?: boolean;
  params?: {
    campaign_type: string[];
  };
}

export const CampaignSelect = ({ params, showId, ...props }: CampaignSelectProps) => {
  const [search, setSearch] = useState(null);

  const debouncedSetSearch = useCallback(
    debounce((value: string) => setSearch(value), 500),
    []
  );

  useEffect(() => {
    // Cleanup debounce on unmount
    return () => {
      debouncedSetSearch.cancel();
    };
  }, [debouncedSetSearch]);

  const handleSearch = useCallback(
    (value: string) => {
      if (search !== value) {
        debouncedSetSearch(value);
      }
    },
    [debouncedSetSearch, search]
  );

  const { data = [], isLoading } = useCampaignList({ search_string: search, ...params });

  const options = data.map(item => ({
    value: item.id,
    label: showId ? `${item.name} (ID: ${item.id})` : item.name
  }));

  return (
    <Select
      showSearch
      onBlur={e => e.preventDefault()}
      onSearch={handleSearch}
      options={options}
      loading={isLoading}
      filterOption={(input, option) =>
        (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
      }
      notFoundContent={isLoading ? <Spin size="small" /> : null}
      {...props}
    />
  );
};

export default CampaignSelect;
