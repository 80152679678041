import {
  CustomerDeactivationModalKey,
  CustomerDetailsKey,
  CustomerListPageKey,
  CustomerListTableKey,
  CustomerPendingListTableKey,
  CustomerUploadModalKey
} from '../../TModules';

export const translations = {
  [CustomerListTableKey]: {
    Identifier: 'Kennung',
    Name: 'Name',
    Status: 'Status',
    DateJoined: 'Beitrittsdatum',
    Actions: 'Aktionen',
    SearchCustomers: 'Kunden suchen',
    Activate: 'Aktivieren',
    Deactivate: 'Deaktivieren',
    Active: 'Aktiv',
    Inactive: 'Inaktiv',
    Initial: 'Initial'
  },
  [CustomerUploadModalKey]: {
    HandleUpload: {
      SuccessMessage: 'Datei hochgeladen',
      ErrorMessage: 'Fehler beim Hochladen der Datei.',
      Title: 'Kundendatei hochladen',
      OkText: 'Hochladen',
      CancelText: 'Abbrechen',
      UploadButton: 'Hochladen',
      UploadDraggerMessage:
        'Klicken oder ziehen Sie die Datei in diesen Bereich, um sie hochzuladen',
      DownloadSample: 'Beispiel herunterladen',
      AcceptedFormat: 'Akzeptiertes Format: Nur .txt., .xlsx- oder .csv-Dateien'
    }
  },
  [CustomerPendingListTableKey]: {
    RequestId: 'Anfrage-ID',
    Customer: 'Kunde',
    RequestType: 'Anfragetyp',
    Status: 'Status',
    Comment: 'Kommentar',
    LastUpdated: 'Zuletzt aktualisiert',
    DateOfExpiry: 'Ablaufdatum',
    Actions: 'Aktionen',
    Comments: 'Kommentare',
    ViewDetails: 'Details anzeigen',
    Approve: 'Genehmigen',
    ApproveRequests: 'Anfragen genehmigen',
    CustomerStampView: 'Kundenstempelansicht',
    LoyaltyProgramDetails: 'Treueprogramm-Details',
    CustomerGameView: 'Kundenspielansicht',
    CustomerRewardView: 'Kundenbelohnungsansicht',
    ApproveSelectedButton: 'Ausgewählte genehmigen',
    ApproveMultipleMessageModal: {
      SuccessMessage: 'Uploads genehmigt',
      FailureMessage: 'Fehler beim Genehmigen der Datei.',
      Title: 'Mehrere Anfragen genehmigen?',
      OkText: 'Ausgewählte genehmigen',
      DescriptionText: `
      Möchten Sie wirklich alle ausgewählten Anfragen genehmigen?
      <br />
      Bitte überprüfen Sie die Details, bevor Sie genehmigen, da diese Aktion nicht rückgängig gemacht werden kann.`
    },
    ApproveRequestModal: {
      SuccessMessage: 'Anfrage wurde genehmigt',
      FailureMessage: 'Fehler beim Genehmigen der Anfrage.',
      Title: 'Anfrage genehmigen?',
      OkText: 'Genehmigen',
      DescriptionText: `Möchten Sie diese Anfrage wirklich genehmigen?`
    },
    UploadCommentModal: {
      ViewAndAddComments: 'Kommentare anzeigen und hinzufügen',
      Content: 'Inhalt',
      DeleteButton: 'Löschen Taste',
      Time: 'Zeit',
      ErrorAddingComment: 'Fehler beim Hinzufügen des Kommentars',
      CommentAdded: 'Kommentar hinzugefügt',
      EnterYourCommentHere: 'Geben Sie hier Ihren Kommentar ein',
      AddComment: 'Kommentar hinzufügen',
      CommentsAdded: 'Kommentare hinzugefügt',
      Delete: 'Löschen'
    },
    DeleteCommentModal: {
      CommentDeleted: 'Kommentar gelöscht',
      ErrorDeletingComment: 'Fehler beim Löschen des Kommentars',
      DeleteComment: 'Kommentar löschen?',
      Delete: 'Löschen',
      Description: `
      Möchten Sie Ihren Kommentar wirklich löschen?
      <br />
      Diese Aktion kann nicht rückgängig gemacht werden.
      `
    }
  },
  [CustomerListPageKey]: {
    PendingRequests: 'Ausstehende Anfragen',
    Customers: 'Kunden',
    CustomersList: 'Kundenliste',
    Upload: 'Hochladen',
    TransactionRequests: 'Transaktionsanfragen'
  },
  [CustomerDeactivationModalKey]: {
    ProvideReasonForDeactivation: 'Grund für Deaktivierung angeben',
    ProvideReasonForActivation: 'Grund für Aktivierung angeben',
    Deactivate: 'Deaktivieren',
    Activate: 'Aktivieren',
    Cancel: 'Abbrechen',
    DeactivationReason: 'Bitte Grund auswählen'
  },
  [CustomerDetailsKey]: {
    ID: 'ID',
    CustomerDetails: 'Kundendetails',
    Identifier: 'Kennung',
    UserInfo: 'Benutzerinformationen',
    FirstName: 'Vorname',
    MiddleName: 'Zweiter Vorname',
    LastName: 'Nachname',
    Gender: 'Geschlecht',
    Address: 'Adresse',
    Birthday: 'Geburtstag',
    JoinedAt: 'Beigetreten am',
    CreatedVia: 'Erstellt über',
    ReferralCode: 'Empfehlungscode',
    Email: 'E-Mail',
    Phone: 'Telefon',
    State: 'Bundesland',
    City: 'Stadt',
    Race: 'Rasse',
    Segment: 'Segment',
    AccountId: 'Konto-ID',
    Nationality: 'Nationalität',
    AlternateId: 'Alternative ID',
    ProductType: 'Produkttyp',
    Classification: 'Klassifikation',
    LocationState: 'Standort Bundesland',
    PreferredLanguage: 'Bevorzugte Sprache',
    FindRewardByNameTypeOrMerchant: 'Belohnung nach Name, Typ oder Händler finden',
    IssueRewards: 'Belohnungen ausstellen',
    ErrorRetrievingCustomer: 'Fehler beim Abrufen des Kunden',
    CustomerRewards: 'Kundenbelohnungen',
    LoyaltyProgram: 'Treueprogramm',
    AccountDetails: 'Kontodetails',
    Campaigns: 'Kampagnen',
    CustomerIDNotFound: 'Kunden-ID nicht gefunden',
    DeleteUser: 'Benutzer löschen',
    Activate: 'Aktivieren',
    Deactivate: 'Deaktivieren',
    ACTIVE: 'AKTIV',
    Inactive: 'Inaktiv',
    DeactivationReason: 'Deaktivierungsgrund',

    Name: 'Name',
    Status: 'Status',
    Tenure: 'Amtszeit',
    Tier: 'Stufe',
    Balance: 'Guthaben',
    ToNextTier: 'Zur nächsten Stufe',
    MembershipExpiry: 'Mitgliedschaftsablauf',

    Rewards: {
      FindRewardByNameTypeOrMerchant: 'Belohnung nach Name, Typ oder Händler finden',
      IssueRewardsManually: 'Belohnungen manuell ausstellen',
      Issued: 'Ausgestellt',
      Pending: 'Ausstehend',
      GiftedAway: 'Verschenkt',
      Redeemed: 'Eingelöst',
      RedemptionInProgress: 'Einlösung in Bearbeitung',
      FullyRedeemed: 'Vollständig eingelöst',
      Voided: 'Ungültig gemacht',
      Expired: 'Abgelaufen',
      Archived: 'Archiviert',

      Name: 'Name',
      DeletionRequested: 'Löschung angefordert',
      ID: 'ID',
      Merchant: 'Händler',
      DateOfIssued: 'Ausstellungsdatum',
      ValidFrom: 'Gültig von',
      ValidTo: 'Gültig bis',
      VoucherCode: 'Gutscheincode',
      AdminFee: 'Verwaltungsgebühr',
      Action: 'Aktion',
      DateOfExpired: 'Ablaufdatum',
      DateOfGifted: 'Geschenkdatum',
      DateOfRedeemed: 'Einlösedatum',
      RequestId: 'Anfrage-ID',
      Status: 'Status',
      RewardId: 'Belohnungs-ID',
      IssuedOn: 'Ausgestellt am',

      VoidVoucher: 'Gutschein ungültig machen',
      ChangeExpiryDate: 'Ablaufdatum ändern',
      AmendReward: 'Belohnung ändern',
      UndoRedeem: 'Einlösung rückgängig machen',
      ThereIsNoActionForGiftedVoucher: 'Keine Aktion für verschenkten Gutschein.',
      RevertBackToIssued: 'Zu Ausgestellt zurückkehren',
      WaiveAdminFee: 'Verwaltungsgebühr erlassen',
      DeleteReward: 'Belohnung löschen',
      ViewComments: 'Kommentare anzeigen',
      ApproveIssue: 'Ausgabe genehmigen',

      ViewAndAddComments: 'Kommentare anzeigen und hinzufügen',
      RequestHasBeenApproved: 'Anfrage wurde genehmigt',
      SomethingWentWrong: 'Etwas ist schief gelaufen',
      HandleUndoRedeemErrorMessage:
        'Einlösung kann nicht rückgängig gemacht werden - bitte ändern Sie das Ablaufdatum und versuchen Sie es erneut.',
      HandleUndoRedeemConfirmModalTitle: 'Bestätigen',
      HandleUndoRedeemConfirmModalMessage:
        'Dies wird den Gutscheinzustand auf Ausgestellt zurücksetzen - fortfahren?',

      HandleVoidVoucherMakerCheckerSuccessMessage:
        'Ungültigmachungsantrag zur Genehmigung eingereicht',
      HandleVoidVoucherSuccessMessage: 'Gutschein ist ungültig gemacht',
      HandleVoidVoucherConfirmModalMakerCheckerRightButtonText: 'Ungültigmachung beantragen',
      HandleVoidVoucherConfirmModalRightButtonText: 'Bestätigen',
      HandleVoidVoucherConfirmModalMakerCheckerTitle: 'Ungültigmachungsantrag einreichen?',
      HandleVoidVoucherConfirmModalTitle: 'Bestätigen',
      HandleVoidVoucherConfirmModalMakerCheckerMessage:
        'Sind Sie sicher, dass Sie die Ungültigmachung des Belohnungsgutscheins beantragen möchten? Einmal genehmigt, können Sie ihn nicht wiederherstellen.',
      HandleVoidVoucherConfirmModalMessage:
        'Dies wird den Belohnungsgutschein ungültig machen - fortfahren?',

      HandleRevertRedemptionToIssuedConfirmModalTitle: 'Bestätigen',
      HandleRevertRedemptionToIssuedMessageConfirmModalMessage:
        'Dies wird den Gutscheinzustand auf Ausgestellt zurücksetzen - fortfahren?',

      ShowOCBCDemoConfirmModalTitle: 'Bestätigen',
      ShowOCBCDemoConfirmModalMessage: 'Dies wird die Verwaltungsgebühr erlassen - fortfahren?'
    },

    IssueRewardsModal: {
      Title: 'Belohnungen manuell ausstellen',
      OkText_one: '{{count}} Belohnung ausstellen',
      OkText_other: '{{count}} Belohnungen ausstellen',
      SearchPlaceholder: 'Belohnung nach Name, Typ oder Händler finden',
      ActiveTabLabel: 'Aktiv',
      PrivateTabLabel: 'Privat'
    },
    IssueRewardsTable: {
      Name: 'Name',
      Merchant: 'Händler',
      ValidityPeriod: 'Gültigkeitszeitraum',
      Balance: 'Guthaben'
    },
    AuditLog: {
      Title: 'Audit-Protokoll',
      LastUpdatedAt: 'Zuletzt aktualisiert am',
      IpAddress: 'IP-Adresse',
      User: 'Benutzer',
      Action: 'Aktion',
      Description: 'Beschreibung',
      RenderSummaryIsCreated: 'erstellt',
      RenderSummaryIsUpdated: 'aktualisiert'
    },
    DeleteCustomerModal: {
      Title: 'Benutzer löschen?',
      OkText: 'bestätigen',
      CancelText: 'Zurückgehen',
      DescriptionLineOne: 'Möchten Sie diesen Benutzer wirklich löschen?',
      DescriptionLineTwo: 'Diese Aktion kann nicht rückgängig gemacht werden.',
      Name: 'Name:',
      Identifier: 'Kennung:'
    },

    LoyaltyProgramsTable: {
      Name: 'Name',
      Status: 'Status',
      Tenure: 'Amtszeit',
      Tier: 'Stufe',
      Balance: 'Guthaben',
      ToNextTier: 'Zur nächsten Stufe',
      MembershipExpiry: 'Mitgliedschaftsablauf',
      Actions: 'Aktionen'
    },
    LoyaltyProgramDetailsModal: {
      Title: 'Treueprogramm-Details',
      CloseButton: 'SCHLIESSEN',
      Active: 'Aktiv',
      Pending: 'Ausstehend',
      Actions: 'Aktionen',
      ActionCancelRequest: 'Anfrage stornieren',
      ActionApproveIssue: 'Ausgabe genehmigen',
      ActionApproveDeletion: 'Löschung genehmigen',
      ActionDenyDeletion: 'Löschung ablehnen',
      ActionComments: 'Kommentare',
      ActionCommentsModalTitle: 'Kommentare anzeigen und hinzufügen',

      Event: 'Ereignis',
      EventButton: 'Löschung angefordert',
      Status: 'Status',
      SubmissionDate: 'Einreichungsdatum',
      Points: 'Punkte',
      ExpiresAt: 'Läuft ab am',
      TransactionId: 'Transaktions-ID',
      AmountTransacted: 'Transaktionsbetrag',
      TransactionDate: 'Transaktionsdatum',
      TransactionRef: 'Transaktionsreferenz',
      Merchant: 'Händler',
      GlAccountNumber: 'GL-Kontonummer',
      CostCenterId: 'Kostenstellen-ID',
      BranchCode: 'Filialcode',

      ChangeStatus: 'Status ändern',
      ChangeTier: 'Stufe ändern',
      AddOrRemovePoints: 'Punkte hinzufügen / entfernen',

      ChangeStatusForm: {
        Active: 'Aktiv',
        Inactive: 'Inaktiv',
        Save: 'Speichern'
      },
      ChangeTierForm: {
        Label: 'Stufe überschreiben läuft ab am:',
        Save: 'Speichern'
      },
      PointsForm: {
        SelectGlAccount: 'GL-Konto auswählen',
        GlAccountType: 'GL-Kontotyp',
        GlAccountName: 'GL-Kontoname',
        Message: 'Schreiben Sie eine Nachricht zu dieser Punkteanpassung.',
        AdjustPointsButton: 'Punkte anpassen'
      },
      UpdateLoyaltyProgramCustomerStateSuccessMessage: 'Status aktualisiert',
      UpdateLoyaltyProgramCustomerStateErrorMessage: 'etwas ist schief gelaufen',
      UpdateLoyaltyProgramCustomerTierSuccessMessage: 'Stufe aktualisiert',
      UpdateLoyaltyProgramCustomerTierErrorMessage: 'etwas ist schief gelaufen',
      CreateCustomerLoyaltyTransaction: {
        GLAccountTypeRequiredMessage: 'GL-Kontotyp ist erforderlich',
        GlAccountNameRequiredMessage: 'GL-Kontoname ist erforderlich',
        MakerCheckerSuccessMessage: 'Punktausgabe-Antrag zur Genehmigung eingereicht',
        SuccessMessage: 'Transaktion hinzugefügt',
        ErrorMessage: 'etwas ist schief gelaufen'
      },
      DeleteCustomerRequestLoyaltyTransaction: {
        SuccessMessage: 'Anfrage wurde storniert',
        ErrorMessage: 'etwas ist schief gelaufen'
      },
      ApproveDenyCustomerRequestLoyaltyTransaction: {
        ApproveSuccessMessage: 'Anfrage wurde ',
        ErrorMessage: 'etwas ist schief gelaufen'
      },
      DeleteCustomerLoyaltyTransaction: {
        MakerCheckerSuccessMessage: 'Transaktionslöschungsantrag zur Genehmigung eingereicht',
        SuccessMessage: 'Transaktion gelöscht',
        ErrorMessage: 'etwas ist schief gelaufen'
      },
      RecoverCustomerLoyaltyTransaction: {
        SuccessMessage: 'Transaktion wiederhergestellt',
        ErrorMessage: 'etwas ist schief gelaufen'
      },
      DeleteCustomerLoyaltyTransactionModal: {
        ActiveTitle: 'Löschungsantrag einreichen?',
        Title: 'Anfrage stornieren?',
        ActiveOkText: 'Löschung beantragen',
        OkText: 'Anfrage stornieren',
        ActiveCancelText: 'Abbrechen',
        CancelText: 'Zurück',
        ActiveMessage:
          'Sind Sie sicher, dass Sie die Löschung dieser Punkte beantragen möchten? Einmal genehmigt, können Sie sie nicht wiederherstellen.',
        MessageLineOne: `Die Ausgabe dieser Punkte steht noch zur Genehmigung aus. Möchten Sie die Anfrage wirklich stornieren?`,
        MessageLineTwo: 'Diese Aktion kann nicht rückgängig gemacht werden.'
      },
      ApproveDenyModal: {
        ApproveIssueTitle: 'Belohnungsausgabe genehmigen',
        ApproveDeletionTitle: 'Belohnungslöschung genehmigen',
        DenyDeletionTitle: 'Belohnungslöschung ablehnen',
        DenyDeletionOkText: 'Ablehnen',
        OkText: 'Genehmigen',
        ApproveIssueAndDeletionMessageLineOne: 'Sind Sie sicher, dass Sie die',
        ApproveIssueAndDeletionMessageLineTwo:
          'dieser Belohnung genehmigen möchten? Diese Aktion kann nicht rückgängig gemacht werden.',
        Message: ` Sind Sie sicher, dass Sie die Löschung dieser Belohnung ablehnen möchten? Diese Aktion kann nicht
                    rückgängig gemacht werden, und die Belohnung bleibt im ausstehenden Zustand, bis sie genehmigt wird.`
      },

      LineProgressBar: {
        HeaderDateText: 'Beginn des Zeitraums',
        CustomerTierText: 'Der Benutzer befindet sich derzeit in',
        Tier: 'Stufe',
        CustomerForcedMinimumTierText: 'Der Benutzer wurde in die Mindeststufe gezwungen',
        CustomerForcedMaximumTierText: `Der Benutzer wurde `,
        FORCED: 'GEZWUNGEN',
        into: 'in',
        EndOfPeriod: 'Ende des Zeitraums',
        ForcedTier: 'Erzwungene Stufe',
        ForcedMinTier: 'Erzwungene Mindeststufe',
        UserHasBeen: 'Benutzer wurde',
        Forced: 'gezwungen',
        To: 'zu',
        Status: 'Status',
        Until: 'bis',
        DeleteForcedTierLinkText: 'X Erzwungene Stufe deaktivieren',
        ToMinimum: 'auf Minimum',
        TheUserHasEarned: 'Der Benutzer hat verdient',
        Points: 'Punkt(e)',
        DuringThisCycle: 'während dieses Zyklus.',
        TheUserRequires: 'Der Benutzer benötigt',
        ToLevelUpTo: 'um aufzusteigen zu',
        TheUserHas: 'Der Benutzer hat',
        Available: 'verfügbar.'
      }
    },

    CustomerCampaigns: {
      ID: 'ID',
      HitThePinata: 'Auf die Piñata schlagen',
      ScratchCard: 'Rubbellos',
      Plinko: 'Plinko',
      Name: 'Name',
      CampaignType: 'Kampagnentyp',
      Status: 'Status',
      StartDate: 'Startdatum',
      EndDate: 'Enddatum',
      Actions: 'Aktionen',
      SearchCampaignsPlaceholder: 'Kampagnen suchen',
      UnenrollCustomer: 'Kunden abmelden',
      NotStarted: 'Nicht gestartet',
      Active: 'Aktiv',
      Completed: 'Abgeschlossen',
      NotCompleted: 'Nicht abgeschlossen',
      Enrollable: 'Einschreibbar',
      EndedAndNotEnrolled: 'Beendet und nicht eingeschrieben',
      Enrolled: 'Eingeschrieben',
      NotEnrolled: 'Nicht eingeschrieben',
      SpinTheWheel: 'Drehen Sie das Rad',
      ShakeTheTree: 'Den Baum schütteln',
      EnrollCustomer: 'Kunden einschreiben',
      SelectCampaignsPlaceholder: 'Kampagnen auswählen',

      SelectedTypeCampaigns: {
        Customers: 'Kunden',
        Campaigns: 'Kampagnen',
        StampCampaign: 'Stempelkampagne',
        QuizCampaign: 'Quizkampagne',
        SurveyCampaign: 'Umfragekampagne',
        GameCampaign: 'Spielkampagne',
        ProgressCampaign: 'Fortschrittskampagne',
        QuestCampaign: 'Questkampagne',
        InstantRewardCampaign: 'Sofortbelohnungskampagne',
        CampaignProgress: 'Dieser Kunde befindet sich derzeit in'
      },

      RaffleView: {
        TriggerName: 'Auslösername',
        RaffleRuleID: 'Raffleregel-ID',
        TransactionDetails: 'Transaktionsdetails',
        TransactionRuleID: 'Transaktionsregel-ID',
        TriggeredDateOrTime: 'Ausgelöstes Datum / Uhrzeit',
        RaffleCode: 'Rafflec-Code',
        State: 'Status'
      },

      InstantRewardView: {
        TriggerName: 'Auslösername',
        RuleID: 'Regel-ID',
        TransactionDetails: 'Transaktionsdetails',
        TriggeredDateOrTime: 'Ausgelöstes Datum / Uhrzeit'
      },

      QuestView: {
        TriggerName: 'Auslösername',
        Task: 'Aufgabe',
        QuestRuleID: 'Quest-Regel-ID',
        TransactionDetails: 'Transaktionsdetails',
        TransactionRuleID: 'Transaktionsregel-ID',
        TriggeredDateOrTime: 'Ausgelöstes Datum / Uhrzeit',
        State: 'Status',
        Actions: 'Aktionen',
        MarkCompleted: 'Als abgeschlossen markieren',
        MarkCompletedSuccessMessage: 'Punkte erfolgreich aktualisiert!',
        MarkCompletedErrorMessage: 'Fehler beim Aktualisieren der Punkte'
      },

      CustomerProgressCard: {
        TriggerName: 'Auslösername',
        ProgressRuleID: 'Fortschrittsregel-ID',
        TransactionDetails: 'Transaktionsdetails',
        TransactionID: 'Transaktions-ID',
        TriggeredDateOrTime: 'Ausgelöstes Datum / Uhrzeit',
        Points: 'Punkte'
      },

      CustomerGameView: {
        TriesID: 'Versuche-ID',
        Status: 'Status',
        AcquiredVia: 'Erworben über',
        ReasonCode: 'Begründungscode',
        Email: 'E-Mail',
        ID: 'ID',
        DateOfIssue: 'Ausstellungsdatum',
        DateOfExpiry: 'Ablaufdatum',
        Outcomes: 'Ergebnisse',
        VoucherID: 'Gutschein-ID',
        NoOutcome: 'kein Ergebnis',
        SorrySomethingWentWrong: 'Entschuldigung, etwas ist schief gelaufen!'
      },

      CustomerQuizAndSurveyView: {
        QuestionNo: 'Fragen-Nr.',
        Question: 'Frage',
        Response: 'Antwort',
        SubmissionDateOrTime: 'Einreichungsdatum / -uhrzeit',
        Result: 'Ergebnis'
      },

      IssueGameChangeModal: {
        MakerCheckerSuccessMessage: 'Spielchance-Ausgabeantrag zur Genehmigung eingereicht',
        SuccessMessage: 'Spielchancen erfolgreich ausgestellt!',
        ErrorMessage: 'Fehler beim Ausstellen der Spielchancen',
        IssueGameTriesButton: 'Spielversuche ausstellen',
        Title: 'Spielchance ausstellen an',
        OkText: 'Chance ausstellen',
        HowManyChances: 'Wie viele Chancen?',
        ReasonForIssuance: 'Grund für die Ausstellung'
      },

      CustomerStampCards: {
        CardHash: 'Karte #',
        StampCardID: 'Stempelkarte-ID',
        State: 'Status',
        ExpiryDate: 'Ablaufdatum',
        DateOfParticipation: 'Teilnahmedatum',
        StampsCollected: 'Gesammelte Stempel',
        CompletedDate: 'Abschlussdatum',
        RewardsAchieved: 'Erreichte Belohnungen',

        Active: 'Aktiv',
        Completed: 'Abgeschlossen',
        Expired: 'Abgelaufen'
      },

      IssueStampsModal: {
        MakerCheckerSuccessMessage: 'Stempelausgabeantrag zur Genehmigung eingereicht',
        SuccessMessage: 'Stempel erfolgreich ausgestellt!',
        ErrorMessage: 'Fehler beim Ausstellen der Stempel',
        IssueStampsButton: 'Stempel ausstellen',
        Title: 'Stempel ausstellen an',
        OkText: 'Stempel ausstellen',
        HowManyStamps: 'Wie viele Stempel?',
        ReasonForIssuance: 'Grund für die Ausstellung'
      },

      IssueRewardModal: {
        RewardIssuedSuccessfully: 'Belohnung erfolgreich ausgestellt!',
        ErrorIssueReward: 'Fehler bei der Belohnungsausgabe',
        IssueRewardTitle: 'Belohnungen ausstellen',
        IssueRewardOkText: 'Belohnung ausstellen',
        IssueRewardButton: 'Belohnung ausstellen',
        ReasonForAdjustment: 'Grund für die Anpassung'
      },

      AddRemovePointsModal: {
        PointsUpdatedSuccessfully: 'Punkte erfolgreich aktualisiert!',
        ErrorUpdatingPoints: 'Fehler beim Aktualisieren der Punkte',
        AddOrRemovePointsButton: 'Punkte hinzufügen/entfernen',
        AdjustProgressCampaignPoints: 'Fortschrittskampagnenpunkte anpassen',
        OkText: 'Punkte anpassen',
        HowManyPointsToAddOrRemove: 'Wie viele Punkte hinzufügen/entfernen?',
        ReasonForAdjustment: 'Grund für die Anpassung'
      }
    }
  }
};
