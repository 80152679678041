import { CustomerModule } from 'utils/i18n/TModules';

// Translation Keys and Paths
export const CustomerListTableKey = `CustomerListTable`;
export const TCustomerListTable = `${CustomerModule}:${CustomerListTableKey}`;

export const CustomerPendingListTableKey = `CustomerPendingListTable`;
export const TCustomerPendingListTable = `${CustomerModule}:${CustomerPendingListTableKey}`;

export const CustomerListPageKey = `CustomerListPage`;
export const TCustomerListPage = `${CustomerModule}:${CustomerListPageKey}`;

export const CustomerDetailsKey = `CustomerDetails`;
export const TCustomerDetails = `${CustomerModule}:${CustomerDetailsKey}`;

export const CustomerDeactivationModalKey = `CustomerDeactivationModal`;
export const TCustomerDeactivationModal = `${CustomerModule}:${CustomerDeactivationModalKey}`;

export const CustomerUploadModalKey = `CustomerUploadModal`;
export const TCustomerUploadModal = `${CustomerModule}:${CustomerUploadModalKey}`;
