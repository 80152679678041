import React, { useMemo, useReducer } from 'react';
import { Card, Table } from 'antd';
import produce from 'immer';
import _capitalize from 'lodash/capitalize';
import _flowRight from 'lodash/flowRight';
import _isEmpty from 'lodash/isEmpty';
import _isNull from 'lodash/isNull';
import _isNumber from 'lodash/isNumber';
import _join from 'lodash/join';
import _pickBy from 'lodash/pickBy';
import _startCase from 'lodash/startCase';
import moment from 'moment-timezone';
import uuid from 'uuid/v4';

import { DB_RECORD_DATETIME_FORMAT, MID_PAGE_SIZE } from 'appConstants';
import Error from 'containers/Analytics/components/Error';
import { TCustomerDetails } from 'containers/Customer/i18n/TModules';
import usePermissions from 'containers/Permissions/usePermissions';
import { useAxios, useLocalization } from 'hooks';
import useMultiLanguageTranslation from 'hooks/useMultiLanguageTranslation';

import ChangesDetails from './components/ChangesDetail';

const format = _flowRight(_capitalize, _startCase);

export const isCreated = changes =>
  changes['created_at'] && _isNull(changes['created_at'][0]) && !_isNull(changes['created_at'][1]);

export const filterEmptyChanges = data =>
  // filter out empty object values from 'changes' key in response.data
  data.reduce((acc, record) => {
    const r = { ...record };
    const filteredChanges = _pickBy(r.changes, (val, key) => {
      return !val.reduce((acc, curr) => {
        if (_isNumber(curr)) {
          return false;
        }
        return acc && _isEmpty(curr);
      }, true);
    });
    if (!_isEmpty(filteredChanges)) {
      r.changes = filteredChanges;
      acc.push(r);
    }
    return acc;
  }, []);

interface AuditLogState {
  page: number;
  size: number;
}

interface AuditLogProps {
  id: string | number;
  entity: string;
  extraParams?: object;
  isChanges2?: boolean;
}

const AuditLog = ({ id, entity, extraParams, isChanges2 = true }: AuditLogProps) => {
  const [params, paramsDispatch] = useReducer(
    (state: AuditLogState, action): AuditLogState =>
      produce(state, draft => {
        switch (action.type) {
          case 'TABLE':
            return void (draft.page = action.pagination);
        }
      }),
    {
      page: 1,
      size: MID_PAGE_SIZE,
      ...extraParams
    }
  );

  const { canView } = usePermissions('audit_logs');
  const { timezone } = useLocalization();

  const { t } = useMultiLanguageTranslation();

  const URL = isChanges2 ? `/v4/dash/changes2/${entity}/${id}` : `/v4/dash/changes/${entity}/${id}`;

  const [loading, response, error, refetch] = useAxios(URL, { params });

  const renderAction = changes => (isCreated(changes) ? 'Created' : 'Updated');
  const renderSummary = changes => {
    if (isCreated(changes)) {
      return `${format(entity)} ${t(`${TCustomerDetails}.AuditLog.RenderSummaryIsCreated`)}`;
    } else {
      // filter out entries with [null, null]
      const filteredChanges = _pickBy(changes, val => val.some(Boolean));
      const summary = Object.keys(filteredChanges).map(
        k => `${format(k)} ${t(`${TCustomerDetails}.AuditLog.RenderSummaryIsUpdated`)}`
      );
      return _join(summary, ', ');
    }
  };
  const renderChanges = record => <ChangesDetails changes={record.changes} />;

  const onTableChange = (pagination, filters, sorter) =>
    paramsDispatch({ type: 'TABLE', pagination: pagination.current, sorter });

  const dataSource = useMemo(() => {
    const filteredData = response?.data ? filterEmptyChanges(response.data) : [];

    const dataWithKeys = filteredData.map(item => ({ ...item, _id: uuid() }));

    return dataWithKeys;
  }, [response?.data]);

  if (!canView) return null;

  if (error) {
    return (
      <Card
        title={t(`${TCustomerDetails}.AuditLog.Title`)}
        style={{ marginTop: '1rem' }}
        loading={loading}
      >
        <Error retry={refetch} />
      </Card>
    );
  }

  return (
    <Card
      title={t(`${TCustomerDetails}.AuditLog.Title`)}
      style={{ marginTop: '1rem' }}
      loading={loading}
      data-testid="audit-log"
    >
      <Table
        rowKey="_id"
        onChange={onTableChange}
        loading={loading}
        pagination={{
          position: ['bottomRight'],
          current: params.page,
          defaultPageSize: MID_PAGE_SIZE,
          pageSize: params.size,
          total: response?.meta?.total_count || 0
        }}
        dataSource={dataSource}
        columns={[
          {
            title: t(`${TCustomerDetails}.AuditLog.LastUpdatedAt`),
            dataIndex: 'changed_at',
            key: 'changed_at',
            render: (_, record) =>
              moment(record.changed_at).tz(timezone).format(DB_RECORD_DATETIME_FORMAT)
          },
          {
            title: t(`${TCustomerDetails}.AuditLog.IpAddress`),
            dataIndex: 'remote_ip',
            key: 'remote_ip'
          },
          {
            title: t(`${TCustomerDetails}.AuditLog.User`),
            dataIndex: 'user_email',
            key: 'user_email'
          },
          {
            title: t(`${TCustomerDetails}.AuditLog.Action`),
            key: 'action',
            render: (_, record) => renderAction(record.changes)
          },
          {
            title: t(`${TCustomerDetails}.AuditLog.Description`),
            dataIndex: 'changes',
            key: 'changes',
            render: (_, record) => renderSummary(record.changes)
          }
        ]}
        expandRowByClick
        expandable={{
          expandedRowRender: renderChanges
        }}
      />
    </Card>
  );
};

export default AuditLog;
