import {
  CustomerDeactivationModalKey,
  CustomerDetailsKey,
  CustomerListPageKey,
  CustomerListTableKey,
  CustomerPendingListTableKey,
  CustomerUploadModalKey
} from '../../TModules';

export const translations = {
  [CustomerListTableKey]: {
    Identifier: 'Identificatore',
    Name: 'Nome',
    Status: 'Stato',
    DateJoined: 'Data di adesione',
    Actions: 'Azioni',
    SearchCustomers: 'Cerca clienti',
    Activate: 'Attiva',
    Deactivate: 'Disattiva',
    Active: 'Attivo',
    Inactive: 'Inattivo',
    Initial: 'Iniziale'
  },
  [CustomerUploadModalKey]: {
    HandleUpload: {
      SuccessMessage: 'File caricato',
      ErrorMessage: 'Errore durante il caricamento del file.',
      Title: 'Carica file cliente',
      OkText: 'Carica',
      CancelText: 'Annulla',
      UploadButton: 'Carica',
      UploadDraggerMessage: "Fare clic o trascinare il file in quest'area per caricarlo",
      DownloadSample: 'Scarica esempio',
      AcceptedFormat: 'Formato accettato: solo file .txt., .xlsx o .csv'
    }
  },
  [CustomerPendingListTableKey]: {
    RequestId: 'ID richiesta',
    Customer: 'Cliente',
    RequestType: 'Tipo di richiesta',
    Status: 'Stato',
    Comment: 'Commento',
    LastUpdated: 'Ultimo aggiornamento',
    DateOfExpiry: 'Data di scadenza',
    Actions: 'Azioni',
    Comments: 'Commenti',
    ViewDetails: 'Visualizza dettagli',
    Approve: 'Approva',
    ApproveRequests: 'Approva richieste',
    CustomerStampView: 'Vista timbro cliente',
    LoyaltyProgramDetails: 'Dettagli programma fedeltà',
    CustomerGameView: 'Vista gioco cliente',
    CustomerRewardView: 'Vista ricompensa cliente',
    ApproveSelectedButton: 'Approva selezionati',
    ApproveMultipleMessageModal: {
      SuccessMessage: 'Upload approvati',
      FailureMessage: 'Errore nell’approvazione del file.',
      Title: 'Approvare più richieste?',
      OkText: 'Approva selezionati',
      DescriptionText: `
      Sei sicuro di voler approvare tutte le richieste selezionate?
      <br />
      Verifica i dettagli prima di approvare poiché questa azione non può essere annullata.`
    },
    ApproveRequestModal: {
      SuccessMessage: 'Richiesta approvata',
      FailureMessage: `Errore nell'approvazione della richiesta.`,
      Title: 'Approvare la richiesta?',
      OkText: 'Approva',
      DescriptionText: `Sei sicuro di voler approvare questa richiesta?`
    },
    UploadCommentModal: {
      ViewAndAddComments: 'Visualizza e aggiungi commenti',
      Content: 'Contenuto',
      DeleteButton: 'Pulsante elimina',
      Time: 'Tempo',
      ErrorAddingComment: 'Errore nell’aggiunta del commento',
      CommentAdded: 'Commento aggiunto',
      EnterYourCommentHere: 'Inserisci qui il tuo commento',
      AddComment: 'Aggiungi commento',
      CommentsAdded: 'Commenti aggiunti',
      Delete: 'Elimina'
    },
    DeleteCommentModal: {
      CommentDeleted: 'Commento eliminato',
      ErrorDeletingComment: 'Errore nell’eliminazione del commento',
      DeleteComment: 'Eliminare il commento?',
      Delete: 'Elimina',
      Description: `
      Sei sicuro di voler eliminare il tuo commento?
      <br />
      Questa azione non può essere annullata.
      `
    }
  },
  [CustomerListPageKey]: {
    PendingRequests: 'Richieste in sospeso',
    Customers: 'Clienti',
    CustomersList: 'Elenco clienti',
    Upload: 'Carica',
    TransactionRequests: 'Richieste di transazione'
  },
  [CustomerDeactivationModalKey]: {
    ProvideReasonForDeactivation: 'Fornisci motivo per la disattivazione',
    ProvideReasonForActivation: 'Fornisci motivo per l’attivazione',
    Deactivate: 'Disattiva',
    Activate: 'Attiva',
    Cancel: 'Annulla',
    DeactivationReason: 'Seleziona il motivo'
  },
  [CustomerDetailsKey]: {
    ID: 'ID',
    CustomerDetails: 'Dettagli cliente',
    Identifier: 'Identificatore',
    UserInfo: 'Informazioni utente',
    FirstName: 'Nome',
    MiddleName: 'Secondo nome',
    LastName: 'Cognome',
    Gender: 'Genere',
    Address: 'Indirizzo',
    Birthday: 'Compleanno',
    JoinedAt: 'Iscritto il',
    CreatedVia: 'Creato tramite',
    ReferralCode: 'Codice di riferimento',
    Email: 'Email',
    Phone: 'Telefono',
    State: 'Stato',
    City: 'Città',
    Race: 'Razza',
    Segment: 'Segmento',
    AccountId: 'ID account',
    Nationality: 'Nazionalità',
    AlternateId: 'ID alternativo',
    ProductType: 'Tipo di prodotto',
    Classification: 'Classificazione',
    LocationState: 'Stato della posizione',
    PreferredLanguage: 'Lingua preferita',
    FindRewardByNameTypeOrMerchant: 'Trova ricompensa per nome, tipo o commerciante',
    IssueRewards: 'Emetti ricompense',
    ErrorRetrievingCustomer: 'Errore nel recupero del cliente',
    CustomerRewards: 'Ricompense cliente',
    LoyaltyProgram: 'Programma fedeltà',
    AccountDetails: 'Dettagli account',
    Campaigns: 'Campagne',
    CustomerIDNotFound: 'ID cliente non trovato',
    DeleteUser: 'Elimina utente',
    Activate: 'Attiva',
    Deactivate: 'Disattiva',
    ACTIVE: 'ATTIVO',
    Inactive: 'Inattivo',
    DeactivationReason: 'Motivo della disattivazione',

    Name: 'Nome',
    Status: 'Stato',
    Tenure: 'Durata',
    Tier: 'Livello',
    Balance: 'Saldo',
    ToNextTier: 'Al prossimo livello',
    MembershipExpiry: 'Scadenza dell’iscrizione',

    Rewards: {
      FindRewardByNameTypeOrMerchant: 'Trova ricompensa per nome, tipo o commerciante',
      IssueRewardsManually: 'Emetti ricompense manualmente',
      Issued: 'Emesso',
      Pending: 'In sospeso',
      GiftedAway: 'Regalato',
      Redeemed: 'Riscattato',
      RedemptionInProgress: 'Riscatto in corso',
      FullyRedeemed: 'Completamente riscattato',
      Voided: 'Annullato',
      Expired: 'Scaduto',
      Archived: 'Archiviato',

      Name: 'Nome',
      DeletionRequested: 'Eliminazione richiesta',
      ID: 'ID',
      Merchant: 'Commerciante',
      DateOfIssued: 'Data di emissione',
      ValidFrom: 'Valido dal',
      ValidTo: 'Valido fino al',
      VoucherCode: 'Codice voucher',
      AdminFee: 'Commissione amministrativa',
      Action: 'Azione',
      DateOfExpired: 'Data di scadenza',
      DateOfGifted: 'Data di regalo',
      DateOfRedeemed: 'Data di riscatto',
      RequestId: 'ID richiesta',
      Status: 'Stato',
      RewardId: 'ID ricompensa',
      IssuedOn: 'Emesso il',

      VoidVoucher: 'Annulla voucher',
      ChangeExpiryDate: 'Modifica data di scadenza',
      AmendReward: 'Modifica ricompensa',
      UndoRedeem: 'Annulla riscatto',
      ThereIsNoActionForGiftedVoucher: 'Nessuna azione per voucher regalato.',
      RevertBackToIssued: 'Torna a emesso',
      WaiveAdminFee: 'Elimina commissione amministrativa',
      DeleteReward: 'Elimina ricompensa',
      ViewComments: 'Visualizza commenti',
      ApproveIssue: 'Approva emissione',

      ViewAndAddComments: 'Visualizza e aggiungi commenti',
      RequestHasBeenApproved: 'Richiesta approvata',
      SomethingWentWrong: 'Qualcosa è andato storto',
      HandleUndoRedeemErrorMessage:
        'Non è possibile annullare il riscatto - modifica la data di scadenza e riprova.',
      HandleUndoRedeemConfirmModalTitle: 'Conferma',
      HandleUndoRedeemConfirmModalMessage:
        'Questo riporterà lo stato del voucher a Emesso - continuare?',

      HandleVoidVoucherMakerCheckerSuccessMessage:
        'Richiesta di annullamento inviata per approvazione',
      HandleVoidVoucherSuccessMessage: 'Voucher annullato',
      HandleVoidVoucherConfirmModalMakerCheckerRightButtonText: 'Richiedi annullamento',
      HandleVoidVoucherConfirmModalRightButtonText: 'Conferma',
      HandleVoidVoucherConfirmModalMakerCheckerTitle: 'Inviare richiesta di annullamento?',
      HandleVoidVoucherConfirmModalTitle: 'Conferma',
      HandleVoidVoucherConfirmModalMakerCheckerMessage:
        'Sei sicuro di voler richiedere l’annullamento del voucher premio? Una volta approvato, non potrai ripristinarlo.',
      HandleVoidVoucherConfirmModalMessage: 'Questo annullerà il voucher premio - continuare?',

      HandleRevertRedemptionToIssuedConfirmModalTitle: 'Conferma',
      HandleRevertRedemptionToIssuedMessageConfirmModalMessage:
        'Questo riporterà lo stato del voucher a Emesso - continuare?',

      ShowOCBCDemoConfirmModalTitle: 'Conferma',
      ShowOCBCDemoConfirmModalMessage:
        'Questo eliminerà la commissione amministrativa - continuare?'
    },

    IssueRewardsModal: {
      Title: 'Emetti ricompense manualmente',
      OkText_one: 'Emetti {{count}} ricompensa',
      OkText_other: 'Emetti {{count}} ricompense',
      SearchPlaceholder: 'Trova ricompensa per nome, tipo o commerciante',
      ActiveTabLabel: 'Attivo',
      PrivateTabLabel: 'Privato'
    },
    IssueRewardsTable: {
      Name: 'Nome',
      Merchant: 'Commerciante',
      ValidityPeriod: 'Periodo di validità',
      Balance: 'Saldo'
    },
    AuditLog: {
      Title: 'Registro di controllo',
      LastUpdatedAt: 'Ultimo aggiornamento alle',
      IpAddress: 'Indirizzo IP',
      User: 'Utente',
      Action: 'Azione',
      Description: 'Descrizione',
      RenderSummaryIsCreated: 'creato',
      RenderSummaryIsUpdated: 'aggiornato'
    },
    DeleteCustomerModal: {
      Title: 'Eliminare utente?',
      OkText: 'conferma',
      CancelText: 'Torna indietro',
      DescriptionLineOne: 'Sei sicuro di voler eliminare questo utente?',
      DescriptionLineTwo: 'Questa azione non può essere annullata.',
      Name: 'Nome:',
      Identifier: 'Identificatore:'
    },

    LoyaltyProgramsTable: {
      Name: 'Nome',
      Status: 'Stato',
      Tenure: 'Durata',
      Tier: 'Livello',
      Balance: 'Saldo',
      ToNextTier: 'Al prossimo livello',
      MembershipExpiry: 'Scadenza dell’iscrizione',
      Actions: 'Azioni'
    },
    LoyaltyProgramDetailsModal: {
      Title: 'Dettagli programma fedeltà',
      CloseButton: 'CHIUDI',
      Active: 'Attivo',
      Pending: 'In sospeso',
      Actions: 'Azioni',
      ActionCancelRequest: 'Annulla richiesta',
      ActionApproveIssue: 'Approva emissione',
      ActionApproveDeletion: 'Approva eliminazione',
      ActionDenyDeletion: 'Nega eliminazione',
      ActionComments: 'Commenti',
      ActionCommentsModalTitle: 'Visualizza e aggiungi commenti',

      Event: 'Evento',
      EventButton: 'Eliminazione richiesta',
      Status: 'Stato',
      SubmissionDate: 'Data di presentazione',
      Points: 'Punti',
      ExpiresAt: 'Scade il',
      TransactionId: 'ID transazione',
      AmountTransacted: 'Importo transato',
      TransactionDate: 'Data transazione',
      TransactionRef: 'Rif. transazione',
      Merchant: 'Commerciante',
      GlAccountNumber: 'Numero conto GL',
      CostCenterId: 'ID centro di costo',
      BranchCode: 'Codice filiale',

      ChangeStatus: 'Cambia stato',
      ChangeTier: 'Cambia livello',
      AddOrRemovePoints: 'Aggiungi / Rimuovi punti',

      ChangeStatusForm: {
        Active: 'Attivo',
        Inactive: 'Inattivo',
        Save: 'Salva'
      },
      ChangeTierForm: {
        Label: 'Sovrascrivi livello scade il:',
        Save: 'Salva'
      },
      PointsForm: {
        SelectGlAccount: 'Seleziona conto GL',
        GlAccountType: 'Tipo conto GL',
        GlAccountName: 'Nome conto GL',
        Message: 'Scrivi un messaggio per accompagnare questa regolazione punti.',
        AdjustPointsButton: 'Regola punti'
      },
      UpdateLoyaltyProgramCustomerStateSuccessMessage: 'Stato aggiornato',
      UpdateLoyaltyProgramCustomerStateErrorMessage: 'qualcosa è andato storto',
      UpdateLoyaltyProgramCustomerTierSuccessMessage: 'Livello aggiornato',
      UpdateLoyaltyProgramCustomerTierErrorMessage: 'qualcosa è andato storto',
      CreateCustomerLoyaltyTransaction: {
        GLAccountTypeRequiredMessage: 'È richiesto il tipo di conto GL',
        GlAccountNameRequiredMessage: 'È richiesto il nome del conto GL',
        MakerCheckerSuccessMessage: 'Richiesta di emissione punti inviata per approvazione',
        SuccessMessage: 'Transazione aggiunta',
        ErrorMessage: 'qualcosa è andato storto'
      },
      DeleteCustomerRequestLoyaltyTransaction: {
        SuccessMessage: 'Richiesta annullata',
        ErrorMessage: 'qualcosa è andato storto'
      },
      ApproveDenyCustomerRequestLoyaltyTransaction: {
        ApproveSuccessMessage: 'La richiesta è stata ',
        ErrorMessage: 'qualcosa è andato storto'
      },
      DeleteCustomerLoyaltyTransaction: {
        MakerCheckerSuccessMessage:
          'Richiesta di eliminazione transazione inviata per approvazione',
        SuccessMessage: 'Transazione eliminata',
        ErrorMessage: 'qualcosa è andato storto'
      },
      RecoverCustomerLoyaltyTransaction: {
        SuccessMessage: 'Transazione recuperata',
        ErrorMessage: 'qualcosa è andato storto'
      },
      DeleteCustomerLoyaltyTransactionModal: {
        ActiveTitle: 'Inviare richiesta di eliminazione?',
        Title: 'Annullare richiesta?',
        ActiveOkText: 'Richiedi eliminazione',
        OkText: 'Annulla richiesta',
        ActiveCancelText: 'Annulla',
        CancelText: 'Indietro',
        ActiveMessage:
          'Sei sicuro di voler richiedere l’eliminazione di questi punti? Una volta approvato, non potrai ripristinarli.',
        MessageLineOne: `L'emissione di questi punti è in attesa di approvazione. Sei sicuro di voler annullare la richiesta?`,
        MessageLineTwo: 'Questa azione non può essere annullata.'
      },
      ApproveDenyModal: {
        ApproveIssueTitle: 'Approva emissione premi',
        ApproveDeletionTitle: 'Approva eliminazione premi',
        DenyDeletionTitle: 'Nega eliminazione premio',
        DenyDeletionOkText: 'Nega',
        OkText: 'Approva',
        ApproveIssueAndDeletionMessageLineOne: 'Sei sicuro di voler approvare',
        ApproveIssueAndDeletionMessageLineTwo:
          'di questo premio? Questa azione non può essere annullata.',
        Message: ` Sei sicuro di voler negare l'eliminazione di questo premio? Questa azione non può essere
                    annullata, e il premio rimarrà in stato di sospeso fino all'approvazione.`
      },

      LineProgressBar: {
        HeaderDateText: 'inizio periodo',
        CustomerTierText: 'L’utente si trova attualmente in',
        Tier: 'Livello',
        CustomerForcedMinimumTierText: 'L’utente è stato forzatamente inserito nel livello minimo',
        CustomerForcedMaximumTierText: `L’utente è stato `,
        FORCED: 'FORZATO',
        into: 'in',
        EndOfPeriod: 'fine periodo',
        ForcedTier: 'Livello forzato',
        ForcedMinTier: 'Livello minimo forzato',
        UserHasBeen: 'L’utente è stato',
        Forced: 'forzato',
        To: 'a',
        Status: 'stato',
        Until: 'fino a',
        DeleteForcedTierLinkText: 'X Disattiva Livello Forzato',
        ToMinimum: 'al minimo',
        TheUserHasEarned: 'L’utente ha guadagnato',
        Points: 'Punto/i',
        DuringThisCycle: 'durante questo ciclo.',
        TheUserRequires: 'L’utente necessita',
        ToLevelUpTo: 'per salire al livello',
        TheUserHas: 'L’utente ha',
        Available: 'disponibile.'
      }
    },

    CustomerCampaigns: {
      ID: 'ID',
      HitThePinata: 'Colpisci la pentolaccia',
      ScratchCard: 'Gratta e vinci',
      Plinko: 'Plinko',
      Name: 'Nome',
      CampaignType: 'Tipo di campagna',
      Status: 'Stato',
      StartDate: 'Data di inizio',
      EndDate: 'Data di fine',
      Actions: 'Azioni',
      SearchCampaignsPlaceholder: 'Cerca campagne',
      UnenrollCustomer: 'Annulla iscrizione cliente',
      NotStarted: 'Non iniziata',
      Active: 'Attiva',
      Completed: 'Completata',
      NotCompleted: 'Non completata',
      Enrollable: 'Iscrivibile',
      EndedAndNotEnrolled: 'Terminata e non iscritto',
      Enrolled: 'Iscritto',
      NotEnrolled: 'Non iscritto',
      SpinTheWheel: 'Gira la ruota',
      ShakeTheTree: 'Scuoti l’albero',
      EnrollCustomer: 'Iscrivi cliente',
      SelectCampaignsPlaceholder: 'Seleziona campagne',

      SelectedTypeCampaigns: {
        Customers: 'Clienti',
        Campaigns: 'Campagne',
        StampCampaign: 'Campagna timbro',
        QuizCampaign: 'Campagna quiz',
        SurveyCampaign: 'Campagna sondaggio',
        GameCampaign: 'Campagna gioco',
        ProgressCampaign: 'Campagna progresso',
        QuestCampaign: 'Campagna missione',
        InstantRewardCampaign: 'Campagna ricompensa immediata',
        CampaignProgress: 'Questo cliente è attualmente su'
      },

      RaffleView: {
        TriggerName: 'Nome trigger',
        RaffleRuleID: 'ID regola lotteria',
        TransactionDetails: 'Dettagli transazione',
        TransactionRuleID: 'ID regola transazione',
        TriggeredDateOrTime: 'Data / Ora attivazione',
        RaffleCode: 'Codice lotteria',
        State: 'Stato'
      },

      InstantRewardView: {
        TriggerName: 'Nome trigger',
        RuleID: 'ID regola',
        TransactionDetails: 'Dettagli transazione',
        TriggeredDateOrTime: 'Data / Ora attivazione'
      },

      QuestView: {
        TriggerName: 'Nome trigger',
        Task: 'Compito',
        QuestRuleID: 'ID regola missione',
        TransactionDetails: 'Dettagli transazione',
        TransactionRuleID: 'ID regola transazione',
        TriggeredDateOrTime: 'Data / Ora attivazione',
        State: 'Stato',
        Actions: 'Azioni',
        MarkCompleted: 'Segna come completato',
        MarkCompletedSuccessMessage: 'Punti aggiornati con successo!',
        MarkCompletedErrorMessage: 'Errore nell’aggiornamento dei punti'
      },

      CustomerProgressCard: {
        TriggerName: 'Nome trigger',
        ProgressRuleID: 'ID regola progresso',
        TransactionDetails: 'Dettagli transazione',
        TransactionID: 'ID transazione',
        TriggeredDateOrTime: 'Data / Ora attivazione',
        Points: 'Punti'
      },

      CustomerGameView: {
        TriesID: 'ID tentativi',
        Status: 'Stato',
        AcquiredVia: 'Acquisito tramite',
        ReasonCode: 'Codice motivo',
        Email: 'Email',
        ID: 'ID',
        DateOfIssue: 'Data di emissione',
        DateOfExpiry: 'Data di scadenza',
        Outcomes: 'Risultati',
        VoucherID: 'ID voucher',
        NoOutcome: 'nessun risultato',
        SorrySomethingWentWrong: 'Spiacenti, qualcosa è andato storto!'
      },

      CustomerQuizAndSurveyView: {
        QuestionNo: 'Domanda n.',
        Question: 'Domanda',
        Response: 'Risposta',
        SubmissionDateOrTime: 'Data / Ora di invio',
        Result: 'Risultato'
      },

      IssueGameChangeModal: {
        MakerCheckerSuccessMessage:
          'Richiesta di emissione possibilità di gioco inviata per approvazione',
        SuccessMessage: 'Possibilità di gioco emesse con successo!',
        ErrorMessage: 'Errore nell’emissione delle possibilità di gioco',
        IssueGameTriesButton: 'Emetti tentativi di gioco',
        Title: 'Emetti possibilità di gioco a',
        OkText: 'Emetti possibilità',
        HowManyChances: 'Quante possibilità?',
        ReasonForIssuance: 'Motivo dell’emissione'
      },

      CustomerStampCards: {
        CardHash: 'Carta #',
        StampCardID: 'ID carta timbro',
        State: 'Stato',
        ExpiryDate: 'Data di scadenza',
        DateOfParticipation: 'Data di partecipazione',
        StampsCollected: 'Timbri raccolti',
        CompletedDate: 'Data di completamento',
        RewardsAchieved: 'Ricompense raggiunte',

        Active: 'Attivo',
        Completed: 'Completato',
        Expired: 'Scaduto'
      },

      IssueStampsModal: {
        MakerCheckerSuccessMessage: 'Richiesta di emissione timbri inviata per approvazione',
        SuccessMessage: 'Emissione timbri riuscita!',
        ErrorMessage: 'Errore nell’emissione dei timbri',
        IssueStampsButton: 'Emetti timbri',
        Title: 'Emetti timbri a',
        OkText: 'Emetti timbri',
        HowManyStamps: 'Quanti timbri?',
        ReasonForIssuance: 'Motivo dell’emissione'
      },

      IssueRewardModal: {
        RewardIssuedSuccessfully: 'Ricompensa emessa con successo!',
        ErrorIssueReward: 'Errore nell’emissione della ricompensa',
        IssueRewardTitle: 'Emetti ricompense',
        IssueRewardOkText: 'Emetti ricompensa',
        IssueRewardButton: 'Emetti ricompensa',
        ReasonForAdjustment: 'Motivo dell’aggiustamento'
      },

      AddRemovePointsModal: {
        PointsUpdatedSuccessfully: 'Punti aggiornati con successo!',
        ErrorUpdatingPoints: 'Errore nell’aggiornamento dei punti',
        AddOrRemovePointsButton: 'Aggiungi/Rimuovi punti',
        AdjustProgressCampaignPoints: 'Regola punti campagna progresso',
        OkText: 'Regola punti',
        HowManyPointsToAddOrRemove: 'Quanti punti aggiungere/rimuovere?',
        ReasonForAdjustment: 'Motivo dell’aggiustamento'
      }
    }
  }
};
